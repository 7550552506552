import { zodResolver } from "@hookform/resolvers/zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {Box, Button, Card, CardContent, IconButton, InputAdornment, TextField} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { loginUser } from "../services/authenticationService";
import { LoginSchema } from "../schemas/loginSchema";

const schema = z.object({
  username: z
    .string()
    .min(1, { message: "Gebruikersnaam is verplicht" }),
  password: z
    .string()
    .min(1, { message: "Wacht is verplicht" }),
});

type FormData = z.infer<typeof LoginSchema>;

const LoginForm: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginFailureText, setLoginFailureText] = useState<string>("");
  const [loadingText, setLoadingText] = useState<string>("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: FormData) => {
    setLoginFailureText("");
    setLoadingText("aan het inloggen...")
    const token = await loginUser(data);
    setLoadingText("");

    if (!token?.accessToken) {
      console.log("Failed to login. Check your credentials.");
      setLoginFailureText("Gebruikersnaam en/of wachtwoord onjuist!");
    }
    else if (!token?.organizationId) {
      console.log("Organization is mandatory to login as agent. Check your account.");
      setLoginFailureText("Organisatie-id is verplicht!");
    } else {
      console.log("Login successful:", token);
      // Save the token to local storage
      sessionStorage.setItem("jwtToken", token.accessToken);
      navigate("/", { replace: true });

      // Force a full reload to ensure the auth state is updated
      window.location.reload();
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Card sx={{ minWidth: 275 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}
          >
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Gebruikersnaam"
                  variant="outlined"
                  error={!!errors.username}
                  helperText={errors.username?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Wachtwoord"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
              <p style={{ color: 'red'}}>{loginFailureText}</p>
              <div>{loadingText}</div>
            <Button type="submit" variant="contained" sx={{ mt: 3 }}>
              Inloggen
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginForm;
