export enum UserRole {
  CLIENT = "CLIENT",
  AGENT = "AGENT",
  ADMIN = "ADMIN",
}

export interface User {
  id?: number;
  username: string; // Added to match UserDto
  firstName?: string; // Renamed from voornaam to match UserDto
  lastName?: string; // Renamed from achternaam to match UserDto
  userRole: UserRole;
  agentId?: number; // Optional field to match UserDto
}

export interface JwtUserCredentials {
  username: string;
  password: string;
  role?: string;
}

export interface JwtTokenResponse {
  accessToken: string;
  userId: number;
  organizationId?: number;
}