import { jwtDecode } from "jwt-decode";
import { loginUser } from "../services/authenticationService";
import { customLog } from "../services/logService";

export const useJwtTokenHandler = () => {
  const getAndSetJwtToken = async (): Promise<string | null> => {
    try {
      const jwtTokenStorage = sessionStorage.getItem("jwtToken");

      if (!jwtTokenStorage) {
        console.error("There is no JWT token in storage");
        return null;
      }

      const decodedToken: { exp: number } = jwtDecode(jwtTokenStorage);
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = decodedToken.exp - currentTime;

      // refresh 15 seconds before expiry
      if (decodedToken.exp >= currentTime + 15) {
        customLog(`JWT token validation: Token is still valid (${remainingTime})`);
        // customLog(jwtTokenStorage);
        return jwtTokenStorage;
      }

      customLog(`JWT token validation: JwtToken is almost expired`);

      customLog(`JWT token validation: Triggering JWT token refresh authentication`);

      const newToken = await loginUser();
      return newToken?.accessToken!;
    } catch (error: any) {
      customLog(`JWT token validation: Error retrieving the JWT token: ${JSON.stringify(error.message)}`);
      return null;
    }
  };

  return {
    getAndSetJwtToken,
  };
};
