export default function evaluatePasswordStrength(password: string) {
  let strength = "weak";
  
  const regex = {
    uppercase: /[A-Z]/,
    lowercase: /[a-z]/,
    numbers: /[0-9]/,
    specialChars: /[^a-zA-Z0-9]/,
  };

  if (
    password.length >= 8 &&
    regex.uppercase.test(password) &&
    regex.lowercase.test(password) &&
    regex.numbers.test(password) &&
    regex.specialChars.test(password)
  ) {
    strength = "strong";
  } else if (
    password.length >= 6 &&
    (regex.uppercase.test(password) || regex.lowercase.test(password)) &&
    regex.numbers.test(password)
  ) {
    strength = "medium";
  }

  return strength;
}
