import { ComponentType, useEffect, useRef } from "react";
import { useJwtTokenHandler } from "../hooks/useJwtHandler";

function withJwtStatusCheck<T extends object>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const { getAndSetJwtToken } = useJwtTokenHandler();
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const handleJwtTokenFetch = async () => {
      await getAndSetJwtToken();
    };

    useEffect(() => {
      // Check the token immediately upon render
      handleJwtTokenFetch();

      // Run getAndSetJwtToken every 10 seconds
      intervalRef.current = setInterval(() => {
        handleJwtTokenFetch();
      }, 10000);

      // Clear the interval on component unmount
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    });

    return <WrappedComponent {...props} />;
  };
}

export default withJwtStatusCheck;
