import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';

type ComplaintInfoProps = {
    title: string;
    description: string;
};

const ComplaintInfo: React.FC<ComplaintInfoProps> = ({ title, description }) => {
    const theme = useTheme();

    return (
        <Box  sx={{
            p: 2,
            mb: 2,
            backgroundColor: theme.palette.secondary.main,
        }}>
            <Typography variant="h5" component="h2" gutterBottom sx={{
                color: "white",
            }}>
                {title}
            </Typography>
            <Typography sx={{color: "white",  mt: 1.5}} variant="body1">
                {description}
            </Typography>
        </Box>
    );
};

export default ComplaintInfo;
