import axios from 'axios';
import { Complaint } from '../types/Complaint';
import { Message } from '../types/Message';

const baseURL = process.env.REACT_APP_API + "/complaints";

export const fetchAllComplaints = async (accessToken: string, organizationId: number): Promise<Complaint[]> => {
    const response = await axios.get<Complaint[]>(`${baseURL}/organization/${organizationId}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
};

export const fetchComplaintDetails = async (id: number, accessToken: string): Promise<Complaint> => {
    const response = await axios.get<Complaint>(`${baseURL}/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
};

export const createComplaint = async (complaint: Complaint, accessToken: string): Promise<number> => { // Returns the ID of the new complaint
    const response = await axios.post<string>(baseURL, complaint, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
    return parseInt(response.data); // Assuming the API returns the ID as a string
};

export const updateComplaint = async (id: number, complaint: Complaint, accessToken: string): Promise<void> => {
    await axios.put(`${baseURL}/${id}`, complaint, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
};

export const fetchComplaintMessages = async (id: number, accessToken: string): Promise<Message[]> => {
    const response = await axios.get<Message[]>(`${baseURL}/${id}/messages`, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
};

export const createComplaintMessage = async (id: number, message: Message, accessToken: string): Promise<number> => { // Returns the ID of the new message
    const response = await axios.post<string>(`${baseURL}/${id}/messages`, message, {
        headers: { Authorization: `Bearer ${accessToken}` },
    });
    return parseInt(response.data); // Assuming the API returns the ID as a string
};
