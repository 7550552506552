import { z } from "zod";
import { UserRole } from "../types/User";

export const registerUserSchema = z.object({
  email: z.string().min(1, "Email is required").email("Aub een valide email adres invullen."),
  firstName: z
    .string()
    .min(3, "De voornaam moet minimaal drie karakters lang zijn.")
    .max(20, "De voornaam mag maximaal 20 karakters lang zijn.")
    .regex(/^[a-zA-Z ]+$/, "De voornaam mag alleen uit letters en spaties bestaan."),
  lastName: z.string().optional(),
  role: z.nativeEnum(UserRole, {
    errorMap: () => ({ message: "Rol is vereist." }),
  }),
});
