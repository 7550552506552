import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { registerUserSchema } from "../schemas/registerUserScheme";
import { saveUser } from "../services/userService";
import { User, UserRole } from "../types/User";

interface CreateUserFormProps {
  onSave: (user: User) => void;
  onClose?: () => void;
  initialUser?: User;
}

type FormData = z.infer<typeof registerUserSchema>;

const CreateUserForm: React.FC<CreateUserFormProps> = ({ onSave, onClose, initialUser }) => {
  const [userId, setUserId] = useState<number | null>(initialUser?.id || null);

  const isEdit = initialUser !== undefined;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>({
    resolver: zodResolver(registerUserSchema),
    defaultValues: {
      email: initialUser?.username || "",
      firstName: initialUser?.firstName || "",
      lastName: initialUser?.lastName || "",
      role: initialUser?.userRole || UserRole.ADMIN,
    },
  });

  const onSubmit = async (data: FormData) => {
    const validToken = sessionStorage.getItem("jwtToken");
    const { firstName, email, lastName, role } = data;

    // Create or update user object based on the form fields
    const userToSave: User = {
      ...initialUser, // spread existing user data if in edit mode
      username: email,
      firstName,
      lastName,
      userRole: role,
      id: userId!,
    };

    try {
      if (!validToken) {
        console.error("Invalid token");
        setError("email", {
          type: "manual",
          message: "Session expired. Please log in again.",
        });
        return;
      }
      const userResponse = await saveUser(userToSave, validToken, userToSave.id);
      setUserId(userResponse);
      onSave(userToSave);
      console.log(isEdit ? "User update successful" : "User creation successful");
    } catch (error) {
      console.error("Error creating/updating user:", error);
      setError("email", {
        type: "manual",
        message: "Failed to save the user. Please try again.",
      });
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1 },
        padding: 4,
        backgroundColor: "white",
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="Email" type="email" fullWidth error={!!errors.email} helperText={errors.email?.message} />
        )}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="Voornaam" fullWidth error={!!errors.firstName} helperText={errors.firstName?.message} />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextField {...field} label="Achternaam" fullWidth error={!!errors.lastName} helperText={errors.lastName?.message} />
        )}
      />
      <Controller
        name="role"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel sx={{ marginTop: 1.2, marginLeft: 0.5 }}>Rol</InputLabel>
            <Select {...field} sx={{ marginTop: 1.2 }} label="Role" fullWidth error={!!errors.role}>
              {Object.values(UserRole).map((roleValue) => (
                <MenuItem key={roleValue} value={roleValue}>
                  {roleValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {errors.firstName && <p style={{ color: "red" }}>{errors.firstName.message}</p>}
      {errors.lastName && <p style={{ color: "red" }}>{errors.lastName.message}</p>}
      {errors.email && <p style={{ color: "red" }}>{errors.email.message}</p>}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button variant="contained" color="primary" type="submit" sx={{ mr: 1 }}>
          {isEdit ? "Update" : "Save"}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUserForm;
