import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Message } from "../types/Message";
import { User } from "../types/User";

interface ChatProps {
  messages: Message[];
  onSend: (message: string) => void;
  users?: () => Promise<User[]>;
}
export const Chat = ({ messages, onSend }: ChatProps) => {
  const [newMessage, setNewMessage] = useState<string>("");

  // const role = UserRole.AGENT;
  const theme = useTheme();

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      onSend(newMessage);
      setNewMessage("");
    }
  };

  const isClientMessage = (message: Message): boolean => {
    const userId =  Number(sessionStorage.getItem("userId"));
    return message.userId !== userId;
  };

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <Box sx={{ margin: "auto" }}>
      <Box sx={{ overflowY: "auto", scrollBehavior: "auto", maxHeight: 650, marginBottom: 2, borderWidth: 5 }}>
        {messages?.map((comment: Message, index: number) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: isClientMessage(comment) ? "flex-end" : "flex-start",

              my: 1,
              px: 4,
            }}
          >
            <Box
              sx={{
                backgroundColor: isClientMessage(comment) ? theme.palette.primary.main : theme.palette.secondary.main,
                color: theme.palette.primary.contrastText,
                padding: 1,
                borderRadius: 2,
                maxWidth: "75%",
              }}
            >
              <Typography variant="body2">{comment.text}</Typography>
              <Typography variant="caption" sx={{ display: "block" }}>
                {new Date(comment.createdAt).toLocaleTimeString()}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          fullWidth
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Typ een bericht..."
          variant="outlined"
          size="small"
        />
        <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ marginLeft: 2 }}>
          Verzend
        </Button>
      </Box>
    </Box>
  );
};
