import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Chat } from "../../components/Chat";
import ComplaintInfo from "../../components/ComplaintInfo";
import LoadingIndicator from "../../components/LoadingIndicator";
import { fetchComplaintDetails, fetchComplaintMessages } from "../../services/complaintsService";
import { postMessageToComplaint } from "../../services/messageService";
import { Complaint } from "../../types/Complaint";
import { Message } from "../../types/Message";
import withJwtStatusCheck from "../../hoc/withJwtStatusCheck";

const ComplaintDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const [complaint, setComplaint] = useState<Complaint | undefined>(undefined);
  const [messages, setMessages] = useState<Message[]>([]);
  const [timer, setTimer] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTimer(Date.now()), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleSendMessage = (message: string) => {
    const validToken = sessionStorage.getItem("jwtToken");
    const userId =  Number(sessionStorage.getItem("userId"));
    const newMessageObj: Message = {
      _id: 0,
      text: message,
      createdAt: new Date().toISOString(),
      userId: userId,
    };
    setMessages((prevMessages) => [...prevMessages, newMessageObj]);
    postMessageToComplaint(complaint!.id, newMessageObj, validToken!);
  };

  useEffect(() => {
    const fetchData = async () => {
      const validToken = sessionStorage.getItem("jwtToken");
      if (id) {
        if(!validToken) {
          console.error("Invalid token");
          return;
        }

        try {
          const fetchedComplaint = await fetchComplaintDetails(parseInt(id), validToken);
          setComplaint(fetchedComplaint);
          if (fetchedComplaint && fetchedComplaint.messages) {
            setMessages(fetchedComplaint.messages);
          }
        } catch (error) {
          console.error("Failed to fetch complaint details:", error);
        }
      }
    };

    fetchData();
  }, [id]);
  
  useEffect(() => {
    (async () => {
      if (id) {
        const validToken = sessionStorage.getItem("jwtToken");
        if(!validToken) {
          console.error("Invalid token");
          return;
        }
        try {
          const fetchedMessages = await fetchComplaintMessages(parseInt(id), validToken);
          if (fetchedMessages && fetchedMessages.length > 0) {
            setMessages(fetchedMessages);
          }
        } catch (error) {
          console.error("Failed to fetch complaint details:", error);
        }
      }
    })();
  }, [id, timer]);

  if (!complaint) return <LoadingIndicator />;

  return (
    <div>
      <Box>
        <ComplaintInfo title={complaint.title} description={complaint.description}></ComplaintInfo>
      </Box>
      <Box sx={{ px: 3 }}>
        <Chat messages={messages} onSend={handleSendMessage} />
      </Box>
    </div>
  );
};

export default withJwtStatusCheck(ComplaintDetailPage);
