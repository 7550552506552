// NavBar.tsx
import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import AccountCircle from '@mui/icons-material/AccountCircle';

const NavBar: React.FC = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" fontWeight={700} sx={{ flexGrow: 1 }}>
                Vertrouwenspersoon dashboard
                </Typography>
                <Button color="inherit" href="/">Meldingen</Button>
                <Button color="inherit" href="/users">Gebruikers</Button>
                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                >
                    {/*<AccountCircle />*/}
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
