import axios from "axios";
import { User } from "../types/User";

const baseURL = process.env.REACT_APP_API + "/users";

export const fetchAllUsers = async (accessToken: string): Promise<User[]> => {
  try {
    const response = await axios.get<User[]>(`${baseURL}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const fetchUserById = async (userId: number, accessToken: string): Promise<User> => {
  try {
    const response = await axios.get<User>(`${baseURL}/${userId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const saveUser = async (userData: Omit<User, "id">, accessToken: string, id?: number): Promise<number> => {
  let url = `${baseURL}`;
  if (id) {
    // Append the user Id to the URL for update operation
    url += `/${id}`;
  }

  try {
    const response = await axios.request({
      method: id ? "PUT" : "POST",
      url,
      data: userData,
      responseType: "text",
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    console.error(`Error ${id ? "updating" : "creating"} user:`, error);
    throw error;
  }
};

export const deleteUser = async (userId: number, accessToken: string): Promise<void> => {
  try {
    await axios.request({
      method: "DELETE",
      url: `${baseURL}/${userId}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export const resetPasswordInitiate = async (user: User, accessToken: string): Promise<void> => {
  try {
    await axios.post(
      `${baseURL}/user/password/reset`,
      { user: user.username },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  } catch (error) {
    console.error("Error initiating password reset:", error);
    throw error;
  }
};

export const resetPasswordConfirm = async (resetId: string, newPassword: string, accessToken: string): Promise<void> => {
  try {
    await axios.put(
      `${baseURL}/users/password/${resetId}`,
      { password: newPassword },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  } catch (error) {
    console.error("Error confirming password reset:", error);
    throw error;
  }
};
