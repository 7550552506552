import { Container, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DynamicTable from "../components/DynamicTable";
import LoadingIndicator from "../components/LoadingIndicator";
import { fetchAllComplaints } from "../services/complaintsService"; // Adjust the import path as needed
import { Complaint } from "../types/Complaint";
import withJwtStatusCheck from "../hoc/withJwtStatusCheck";

const DashboardPage: React.FC = () => {
  const [complaints, setComplaints] = useState<Complaint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const validToken = sessionStorage.getItem("jwtToken");
    if(!validToken) {
      console.error("Invalid token");
      return;
    }
    let organizationId: number;
    const optionalOrganizationIdAsString = sessionStorage.getItem("organizationId");
    if (optionalOrganizationIdAsString) {
      organizationId = Number(optionalOrganizationIdAsString);
    } else {
      console.error("Unknown organization-id");
      return;
    };

    fetchAllComplaints(validToken, organizationId)
      .then((data: Complaint[]) => {
        setComplaints(data || []); // Ensure that `complaints` is an array, even if `data` is undefined or null
        setIsLoading(false);
      })
      .catch(() => {
        setComplaints([]); // Handle error by setting complaints to an empty array
        setIsLoading(false);
      });
  }, []);

  const handleRowClick = (complaint: Complaint) => {
    navigate(`/complaints/${complaint.id}`);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: theme.spacing(2), display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" gutterBottom component="h1" sx={{ mb: 4 }}>
          Recente Meldingen
        </Typography>
        {isLoading ? (
          <LoadingIndicator />
        ) : complaints.length === 0 ? (
          <Typography variant="body1">Er zijn momenteel geen meldingen om weer te geven.</Typography>
        ) : (
          <DynamicTable<Complaint>
            data={complaints}
            excludeKeys={["id", "messages"]}
            keyLabels={{ title: "Titel", description: "Beschrijving", createdAt: "Aangemaakt Op" }}
            onRowClick={handleRowClick}
          />
        )}
      </Paper>
    </Container>
  );
};

export default withJwtStatusCheck(DashboardPage);
