import { zodResolver } from "@hookform/resolvers/zod";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, Card, CardContent, Divider, List, ListItem, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import React, { ChangeEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { PasswordResetSchema } from "../schemas/passwordResetSchema";
import evaluatePasswordStrength from "./PasswordMeter";

interface PasswordResetFormProps {
  hasOldPassword: boolean;
  isCreatePassword?: boolean;
  onSubmit: (newPassword: string, oldPassword?: string) => void;
}

type FormData = z.infer<typeof PasswordResetSchema>;

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({ hasOldPassword, isCreatePassword, onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("weak");

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    resolver: zodResolver(PasswordResetSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const newPasswordValue = watch("newPassword") || ""; // Default to an empty string if undefined
  const oldPasswordValue = watch("oldPassword") || ""; // Default to an empty string if undefined

  const passwordSuggestions = [
    "At least one lowercase letter",
    "At least one uppercase letter",
    "At least one numeric",
    "At least 8 characters",
  ];

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPassword = event.target.value;
    setPasswordStrength(evaluatePasswordStrength(newPassword));
  };

  const handleResetSubmit = (data: FormData) => {
    onSubmit(data.newPassword, hasOldPassword ? data.oldPassword : undefined);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Card sx={{ maxWidth: 400, mx: "auto", mt: 5 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {isCreatePassword ? "Maak een nieuw wachtwoord aan" : "Wijzig je wachtwoord"}
          </Typography>
          <form onSubmit={handleSubmit(handleResetSubmit)}>
            {/* Conditionally render old password field if resetting an existing password */}
            {!isCreatePassword && hasOldPassword && (
              <Controller
                name="oldPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Oud wachtwoord"
                    type="password"
                    fullWidth
                    required
                    sx={{ mb: 2 }}
                    error={!!errors.oldPassword}
                    helperText={errors.oldPassword?.message}
                  />
                )}
              />
            )}

            {/* Render new password field */}
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nieuw wachtwoord"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                    handleNewPasswordChange(e);
                  }}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message}
                />
              )}
            />

            {/* Render confirm password field */}
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Bevestig nieuwe wachtwoord"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  required
                  sx={{ mb: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                />
              )}
            />

            {/* Submit button with dynamic label */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!newPasswordValue || passwordStrength !== "strong"}
            >
              {isCreatePassword ? "Opslaan" : "Wachtwoord aanpassen"}
            </Button>
          </form>
        </CardContent>
      </Card>

      {/* Password meter and suggestions */}
      {(newPasswordValue.length > 0 || oldPasswordValue.length > 0) && (
        <Card sx={{ marginTop: 3, paddingX: 4 }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", marginY: 2 }} color="text.secondary" gutterBottom>
            {errors.confirmPassword ? "Wachtwoorden komen niet overeen!" : "Kies een wachtwoord"}
          </Typography>
          {!errors.confirmPassword && (
            <>
              <Box
                sx={{
                  width: passwordStrength === "strong" ? "99%" : passwordStrength === "medium" ? "66%" : "33%",
                  bgcolor:
                    passwordStrength === "strong"
                      ? "success.main"
                      : passwordStrength === "medium"
                      ? "warning.main"
                      : "error.main",
                  height: 10,
                }}
              />
              <CardContent sx={{ px: 0 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {passwordStrength === "strong" ? "Strong" : passwordStrength === "medium" ? "Medium" : "Weak"}
                </Typography>

                {/* Suggestions when the password is not strong enough */}
                {passwordStrength !== "strong" && (
                  <>
                    <Divider sx={{ marginY: 2 }} />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {"Suggestions"}
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 4 }}>
                      {passwordSuggestions.map((suggestion) => (
                        <ListItem key={suggestion} sx={{ paddingY: 0, display: "list-item", px: 2 }}>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {suggestion}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
              </CardContent>
            </>
          )}
        </Card>
      )}
    </div>
  );
};

export default PasswordResetForm;
