import axios, { AxiosResponse } from "axios";
import { JwtTokenResponse, JwtUserCredentials } from "../types/User";
import { customLog } from "./logService";

const API_URL = process.env.REACT_APP_API;

export const loginUser = async (credentials?: JwtUserCredentials): Promise<JwtTokenResponse | null> => {
  try {
    customLog(`Login started`, true);

    // get credentials from localstore
    if (credentials === undefined) {
      credentials = JSON.parse(sessionStorage.getItem("credentials") as string);
    } else {
    // update credentials with the right role
      credentials.role = "agent"
    }

    const response: AxiosResponse<JwtTokenResponse> = await axios.post(
      `${API_URL}/user/login`,
      credentials,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Handle the successful response
    const responseData = response.data;

    // Save the access token to sessionStorage
    sessionStorage.setItem("jwtToken", responseData.accessToken);
    sessionStorage.setItem("credentials", JSON.stringify(credentials));
    sessionStorage.setItem("userId", "" + responseData.userId);
    if (responseData.organizationId) {
      sessionStorage.setItem("organizationId", "" + responseData.organizationId);
    } else {
      sessionStorage.removeItem("organizationId");
    }

    customLog(`Login with credentials, stored token: "${responseData.accessToken.slice(-10)}" successfully`, true);

    return responseData;
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      // If the error is Axios-specific and has a response
      customLog(`Login request failed with status ${error.response.status}: ${error.response.data}`, true);
    } else if (error instanceof Error) {
      // Handle generic errors
      customLog(`Login with credentials failed: ${error.message}`, true);
    } else {
      customLog(`Unexpected error occurred during login`, true);
    }

    return null;
  }
};
