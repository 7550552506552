import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CreateUserForm from "../components/CreateUserForm";
import DynamicTable from "../components/DynamicTable";
import LoadingIndicator from "../components/LoadingIndicator";
import { deleteUser, fetchAllUsers, resetPasswordInitiate } from "../services/userService";
import { User } from "../types/User";
import withJwtStatusCheck from "../hoc/withJwtStatusCheck";

const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const theme = useTheme();

  const validToken = sessionStorage.getItem("jwtToken");

  useEffect(() => {
    fetchUsers();
    // we want to fetch users once when the page loads
    // eslint-disable-next-line
  }, []);

  const fetchUsers = () => {
    setIsLoading(true);

    if (!validToken) {
      console.error("Invalid token");
      setIsLoading(false);
      return;
    }

    fetchAllUsers(validToken)
      .then((fetchedUsers: User[]) => {
        setUsers(fetchedUsers);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch users:", error);
        setIsLoading(false);
      });
  };

  const handleDeleteUser = (user: User) => {
    if (!user.id) {
      alert("User id not found");
      return;
    }
    const confirmDelete = window.confirm(`Weet je zeker dat je ${user.firstName} wil verwijderen || "this user"}?`);
    if (confirmDelete) {
      setIsLoading(true);
      if (!validToken) {
        console.error("Invalid token");
        setIsLoading(false);
        return;
      }
      deleteUser(user.id, validToken)
        .then(() => {
          fetchUsers();
        })
        .catch((error) => {
          console.error("Failed to delete user:", error);
          setIsLoading(false);
        });
    }
  };

  const handleCreateUser = () => {
    setSelectedUser(undefined);
    setIsModalOpen(true);
  };

  const handleEditUser = (user: User) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handlePasswordReset = (user: User) => {
    const confirmReset = window.confirm(`Weet je zeker dat je ${user.firstName || "this user"}'s wachtwoord wil wijzigen?`);

    if (confirmReset) {
      setIsLoading(true);
      if (!validToken) {
        console.error("Invalid token");
        setIsLoading(false);
        return;
      }
      resetPasswordInitiate(user, validToken)
        .then(() => {
          fetchUsers();
        })
        .catch((error) => {
          console.error("Failed to reset password:", error);
          setIsLoading(false);
        });
    }
  };

  const handleCreateUserSave = (user: User) => {
    console.log("User saved", user);
    setIsModalOpen(false);
    fetchUsers();
  };

  // Filter out anonymous users and count them
  const anonymousUsers = users.filter(
    (user) => !user.username && !user.firstName && !user.lastName
  );
  const displayedUsers = users.filter(
    (user) => user.username || user.firstName || user.lastName
  );

  return (
    <Box sx={{ mt: 4, mx: 4 }}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button
              startIcon={<AddIcon />}
              onClick={handleCreateUser}
              variant="contained"
              color="primary"
            >
              Nieuwe gebruiker
            </Button>
          </Box>

          <DynamicTable<User>
            data={displayedUsers}
            keyLabels={{ username: "Email" }}
            excludeKeys={["id"]}
            actions={[
              {
                icon: <EditIcon />,
                callback: (user) => handleEditUser(user),
                color: "secondary",
              },
              {
                icon: <LockResetIcon />,
                callback: (user) => handlePasswordReset(user),
                color: "secondary",
              },
              {
                icon: <DeleteIcon />,
                callback: (user) => handleDeleteUser(user),
                color: "primary",
              },
            ]}
          />

          {/* Display anonymous user count */}
          <Typography sx={{ mt: 2, fontSize: 14, fontWeight: "bold", marginLeft: 2 }}>
            Aantal anonieme gebruikers: {anonymousUsers.length}
          </Typography>

          <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby="create-user-modal"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: theme.spacing(2),
                bgcolor: "background.paper",
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[5],
                maxWidth: "500px",
                width: "100%",
              }}
            >
              <Typography
                id="create-user-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 2 }}
              >
                {selectedUser ? "Edit User" : "Create New User"}
              </Typography>
              <CreateUserForm
                onSave={handleCreateUserSave}
                initialUser={selectedUser}
                onClose={() => setIsModalOpen(false)}
              />
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default withJwtStatusCheck(UsersPage);