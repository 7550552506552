import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NavBar from "./components/Navbar";
import ComplaintDetailPage from "./pages/ComplaintDetail/ComplaintDetailPage";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import UsersPage from "./pages/UsersPage";

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#695bf8",
    },
    secondary: {
      main: "#0D3B52",
    },
    background: {
      default: "#fafafa",
    },
  },
});

const isAuthenticated = (): boolean => {
  return !!sessionStorage.getItem("jwtToken");
};

function App() {
  const [auth, setAuth] = useState<boolean>(isAuthenticated());

  useEffect(() => {
    const handleStorageChange = () => {
      setAuth(isAuthenticated());
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {auth && <NavBar />} {/* Show NavBar only if authenticated */}
        <Routes>
          <Route path="/" element={auth ? <DashboardPage /> : <Navigate to="/login" />} />
          <Route path="/complaints/:id" element={auth ? <ComplaintDetailPage /> : <Navigate to="/login" />} />
          <Route path="/users" element={auth ? <UsersPage /> : <Navigate to="/login" />} />
          <Route path="/reset" element={auth ? <PasswordResetPage /> : <Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          {/* Redirect any unknown paths to the login page */}
          <Route path="*" element={<Navigate to={auth ? "/" : "/login"} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
