import React, {ReactElement} from 'react';
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

type DynamicTableProps<T> = {
    data: T[];
    excludeKeys?: (keyof T)[];
    keyLabels?: { [K in keyof T]?: string };
    onRowClick?: (item: T) => void;
    actions?: Array<{
        icon: ReactElement;
        color?: 'inherit' | 'default' | 'primary' | 'secondary';
        callback: (item: T) => void;
    }>;
};

const beautifyKey = <T, >(key: keyof T, keyLabels?: { [K in keyof T]?: string }): string => {
    const stringKey = key as string;
    if (keyLabels && keyLabels[key]) {
        return keyLabels[key] as string;
    }
    return stringKey
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
        .trim();
};

const DynamicTable = <T, >({
                               data,
                               excludeKeys = [],
                               keyLabels,
                               onRowClick,
                               actions,
                           }: DynamicTableProps<T>) => {
    if (data.length === 0) {
        return <div>Geen data beschikbaar.</div>;
    }

    const keys = Object.keys(data[0] as any).filter(key => !excludeKeys.includes(key as keyof T));

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {keys.map((key) => (
                        <TableCell key={key as string}>{beautifyKey(key as keyof T, keyLabels)}</TableCell>
                    ))}
                    {actions && actions.length > 0 && <TableCell>Actions</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, index) => (
                    <TableRow
                        key={index}
                        onClick={() => onRowClick?.(item)}
                        hover={!!onRowClick}
                        style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                    >
                        {keys.map((key) => (
                            <TableCell key={key as string}>{item[key as keyof T] as string} </TableCell>
                        ))}
                        {actions && actions.length > 0 && (
                            <TableCell>
                                {actions.map((action, actionIndex) => (
                                    <IconButton
                                        key={actionIndex}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents onRowClick from being called
                                            action.callback(item);
                                        }}
                                        color={action.color || 'default'}
                                        data-testid={`action-button-${actionIndex}`}
                                    >
                                        {action.icon}
                                    </IconButton>
                                ))}
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default DynamicTable;
