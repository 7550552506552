import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box/*, Typography*/ } from '@mui/material';
import PasswordResetForm from '../components/PasswordResetForm';
import withJwtStatusCheck from "../hoc/withJwtStatusCheck";

export const PasswordResetPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const hasOldPassword = searchParams.get('oldPassword') === 'true';
    
    // Check if a token is present in the URL
    const token = searchParams.get('token');
    const isCreatePassword = Boolean(token);

    const handleSubmit = (newPassword: string, oldPassword?: string) => {
        console.log("Old Password:", oldPassword); // Handle securely
        console.log("New Password:", newPassword); // Handle securely
        // Implement the actual password reset logic here
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <PasswordResetForm hasOldPassword={hasOldPassword} isCreatePassword={isCreatePassword} onSubmit={handleSubmit} />
        </Box>
    );
};

export default withJwtStatusCheck(PasswordResetPage);
