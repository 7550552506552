export const customLog = (message: string, toServer: boolean = false): void => {
  const date = new Date();

  const formattedDate = date
    .toLocaleDateString("nl-NL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/-/g, "/");

  const formattedTime = date.toLocaleTimeString("nl-NL", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  console.log(`[${formattedDate} - ${formattedTime}]: ${message}`);

  // not implemented yet
  // if (toServer) {
  //   logToServer(finalMessage);
  // }
};
