import axios from "axios";
import { Message } from "../types/Message";

const baseURL = process.env.REACT_APP_API + "/complaints";

export const getMessagesByComplaintId = async (complaintId: number, accessToken: string): Promise<Message[]> => {
  try {
    const response = await axios.request({
      url: `${baseURL}/${complaintId}/messages`,
      method: "GET",
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error("getMessagesByComplaintId - Error fetching messages:", error);
    throw error;
  }
};

export const postMessageToComplaint = async (complaintId: number, data: Message, accessToken: string): Promise<Message> => {
  console.log(`postMessageToComplaint - posting message to: ${baseURL}/${complaintId}/messages
    message is: ${JSON.stringify(data)}`);

  try {
    const response = await axios.request({
      url: `${baseURL}/${complaintId}/messages`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      data: JSON.stringify(data),
    });

    if (response.status !== 201) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return data;
  } catch (error) {
    console.error("Error posting message:", error);
    throw error;
  }
};
